import { InvoiceContextProvider } from "../context/invoiceContext";
import InvoicesLayout from "./invoicesLayout";

const InvoicesPage = () => {
  return (
    <InvoiceContextProvider>
      <InvoicesLayout />
    </InvoiceContextProvider>
  );
};

export default InvoicesPage;
