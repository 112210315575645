import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./common/assets/scss/style.scss";
import NavWrapper from "./developers/commons/navbar/navWrapper";

// Developers Routing
// import Marketplace from "./developers/modules/api_marketplace_module/views/marketplace";
// import Billing from "./developers/modules/billing_module/views/billingPage";
// import Keys from "./developers/modules/keys_module/views/keysPage";
import ProjectPage from "./developers/modules/projects_module/views/projectPage.jsx";
import Subscription from "./developers/modules/subscription_module/views/subscriptionPage.jsx";

import CreateProject from "./developers/modules/projects_module/components/createProject";
import ProjectDetails from "./developers/modules/projects_module/components/projectDetails";
import PricingPlanPage from "./developers/modules/subscription_module/pricing_plan_module/views/pricingPlanPage.jsx";
// import SubscriptionUsage from "./developers/modules/subscription_module/views/subscriptionUsage.jsx";

// import CreateConfigurationBilling from "./developers/modules/configurations_module/billing_config_module/components/createBilling";
// import BillingsPage from "./developers/modules/configurations_module/billing_config_module/views/billingsPage";
// import CreateConfigurationProject from "./developers/modules/configurations_module/projects_moduile/components/createProject";
// import ProjectsPage from "./developers/modules/configurations_module/projects_moduile/views/projectsPage";
// import CreateConfigurationSubscription from "./developers/modules/configurations_module/subscription_module/components/createSubscription";
import SubscriptionPage from "./developers/modules/subscription_module/views/subscriptionPage.jsx";
import CertificationPage from "./developers/modules/subscription_module/certification_module/views/certificationPage.jsx";
import AdminLoginPage from "./developers/modules/login_module/views/adminLoginPage.jsx";
import DeveloperLoginPage from "./developers/modules/login_module/views/developerLoginPage.jsx";
import CreateSandbox from "./developers/modules/sandbox_module/components/createSandboxModal.jsx";
import SandboxDetails from "./developers/modules/sandbox_module/components/sandboxDetails.jsx";
import HomePage from "./developers/modules/sandbox_module/views/homePage.jsx";
import AccessRolesPage from "./developers/modules/access_roles_module/views/accessRolesPage.jsx";
import CreateAccessRole from "./developers/modules/access_roles_module/components/createAccessRolesModal.jsx";
import AccessPages from "./developers/modules/access_pages_module/views/accessPages.jsx";
import CreateAccessPage from "./developers/modules/access_pages_module/components/createAccessPagesModal.jsx";
import PortfoliosPage from "./developers/modules/portfolios_module/views/portfoliosPage.jsx";
import NoAccess from "./developers/commons/views/noAccessPage.jsx";
import { userRoutes } from "./developers/commons/navbar/navContent.jsx";
import { DevPrivateRoute } from "./protectedRoute.jsx";
import HmacSimulatorPage from "./developers/modules/resources_module/hmac_simulator_module/views/hmacSimulatorPage.jsx";
import PaymentPage from "./developers/modules/payment_module/views/paymentPage.jsx";
import TxnNetsSuccessStatusPage from "./developers/modules/payment_module/views/txnNetsSuccessStatusPage.jsx";
import TxnNetsFailStatusPage from "./developers/modules/payment_module/views/txnNetsFailStatusPage.jsx";
import InvoicesPage from "./developers/modules/subscription_module/invoices_module/views/invoicesPage.jsx";
import InvoiceDetailsPage from "./developers/modules/subscription_module/invoices_module/components/production/views/invoicesDetailsPage.jsx";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

//Pages
const Landing = React.lazy(() => import("./landing_module/views/LandingPage"));
const PrivacyPolicy = React.lazy(() =>
  import("./landing_module/views/PrivacyPolicyPage")
);

const routeComponents = {
  "/homepage": HomePage,
  "/projects": ProjectPage,
  "/accessroles": AccessRolesPage,
  "/accesspages": AccessPages,
  "/portfolios": PortfoliosPage,
  "/subscriptions": SubscriptionPage,
  "/invoices": InvoicesPage,
  "/resources/hmac-simulator": HmacSimulatorPage,
};

const dynamicRoutes = [
  {
    parent: "/homepage",
    child: {
      "/sandbox/details": SandboxDetails,
      "/sandbox/create": CreateSandbox,
    },
  },
  {
    parent: "/projects",
    child: {
      "/projects/details": ProjectDetails,
      "/projects/create": CreateProject,
    },
  },
  {
    parent: "/accessroles",
    child: {
      "/accessroles/create": CreateAccessRole,
    },
  },
  {
    parent: "/accesspages",
    child: {
      "/accesspages/create": CreateAccessPage,
    },
  },
  {
    parent: "/subscriptions",
    child: {
      "/subscriptions/pricing-plan": PricingPlanPage,
      "/subscriptions/certification": CertificationPage,
      "/subscriptions/invoices": InvoicesPage,
      "/subscriptions/invoices/details": InvoiceDetailsPage,
    },
  },
];

//DEVELOPERS CONSOLE
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subDomain: null,
      load: true,
    };
  }

  componentDidMount() {
    if (this.state.load) {
      const hostname = window.location.hostname;
      let domain;
      // Find & remove protocol (http, ftp, etc.) and get domain
      if (hostname.indexOf("://") > -1) {
        domain = hostname.split("/")[2];
      } else {
        domain = hostname.split("/")[0];
      }
      // Find & remove port number
      domain = domain.split(":")[0];
      const parts = domain.split(".");
      this.setState({ subDomain: parts[0], load: false });
    }
  }

  render() {
    document.title = "OpenApiPass";
    const { subDomain, load } = this.state;
    let readArr = []; // array just to hold routes across the logic flow
    let transformedChildRoutes = []; // array containing child routes that will be rendered after checking their parents

    const findKeyByValue = (object, value) => {
      return Object.keys(object).find((key) => object[key] === value);
    };

    if (!load) {
      if (subDomain === "developers") {
        return (
          <div className="App">
            <BrowserRouter>
              <Routes>
                {/* Login */}
                <Route index element={<DeveloperLoginPage />} />
                <Route
                  restricted={false}
                  path="/adminlogin"
                  name="Login Page"
                  element={<AdminLoginPage />}
                />
                <Route
                  restricted={false}
                  path="/developerlogin"
                  name="Login Page"
                  element={<DeveloperLoginPage />}
                />

                {/* Portfolios */}
                {/* <Route
                  restricted={false}
                  path="/portfolios"
                  name="Portfolios Page"
                  element={<PortfoliosPage />}
                /> */}

                {/* <Route path="/" element={<NavWrapper />}> */}
                <Route path="*" element={<NoAccess />} />
                <Route
                  path="/"
                  element={
                    <DevPrivateRoute>
                      <NavWrapper />
                    </DevPrivateRoute>
                  }
                >
                  <Route path="/payments" element={<PaymentPage />} />
                  <Route path="/success" element={<TxnNetsSuccessStatusPage />} />
                  <Route path="/fail" element={<TxnNetsFailStatusPage />} />
                  {userRoutes.map((route) => {
                    if (route.subNav) {
                      route.subNav.map((subnav) => {
                        readArr.push(routeComponents[subnav.path]);
                      });
                    } else {
                      readArr.push(routeComponents[route.path]);
                    }
                  })}
                  {/* Render parent routes first */}
                  {readArr.map((Component) => {
                    return (
                      <Route
                        path={findKeyByValue(routeComponents, Component)}
                        element={<Component />}
                      />
                    );
                  })}
                  {/* Find child routes after */}
                  {dynamicRoutes.map((a) => {
                    readArr.map((b) => {
                      if (a.parent === findKeyByValue(routeComponents, b)) {
                        for (const [path, Component] of Object.entries(
                          a.child
                        )) {
                          transformedChildRoutes.push({
                            path: path,
                            element: Component,
                          });
                        }
                      }
                    });
                  })}
                  {/* Render child routes in transformed arr */}
                  {transformedChildRoutes.map((data) => {
                    const Component = data.element;
                    return <Route path={data.path} element={<Component />} />;
                  })}
                  {/* <Route path="/developer" element={<NavWrapper />}> */}

                  {/* Admin portal routing */}
                  {/* <Route path="/projects" element={<ProjectPage />} />
                  <Route path="/developer/keys" element={<Keys />} />
                  <Route
                    path="/developer/marketplace"
                    element={<Marketplace />}
                  />
                  <Route path="/developer/billing" element={<Billing />} />
                  <Route
                    path="/developer/subscription"
                    element={<Subscription />}
                  /> */}
                  {/* Admin portal sub pages routing */}
                  {/* <Route
                    path="/projects/details"
                    element={<ProjectDetails />}
                  />
                  <Route path="/projects/create" element={<CreateProject />} />
                  <Route path="/developer/pricing" element={<PricingPlan />} />
                  <Route
                    path="/developer/subscription/usage"
                    element={<SubscriptionUsage />}
                  /> */}

                  {/* Admin portal configurations routing */}
                  {/* <Route
                    path="/resource-groups/configurations/projects"
                    element={<ProjectsPage />}
                  />
                  <Route
                    path="/resource-groups/configurations/projects/create"
                    element={<CreateConfigurationProject />}
                  />
                  <Route
                    path="/resource-groups/configurations/billings"
                    element={<BillingsPage />}
                  />
                  <Route
                    path="/resource-groups/configurations/billings/create"
                    element={<CreateConfigurationBilling />}
                  />
                  <Route
                    path="/resource-groups/configurations/subscriptions"
                    element={<SubscriptionPage />}
                  />
                  <Route
                    path="/resource-groups/configurations/subscriptions/create"
                    element={<CreateConfigurationSubscription />}
                  /> */}
                </Route>
              </Routes>
            </BrowserRouter>
          </div>
        );
      } else {
        return (
          <>
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    name="Landing Page"
                    element={<Landing />}
                  />
                  <Route
                    exact
                    path="/policy/privacy"
                    name="Privacy Policy Page"
                    element={<PrivacyPolicy />}
                  />
                </Routes>
              </React.Suspense>
            </BrowserRouter>
          </>
        );
      }
    }
  }
}

export default App;
