import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Organisation from "../../../../../assets/Organisation.svg";
import Student from "../../../../../assets/Student.svg";
import ComingSoonModal from "../../../../../commons/components/comingSoonModal";
import { commonConfigs, urls } from "../../../../../config/config";
import CourseCard from "./courseCard";
import MembershipCard from "../membershipCard";
import { getUser } from "../../../../../services/tokenService";
import { INVOICE_PLAN_SYSCODE } from "../../../../../commons/constants/invoicePlanTypeSyscode";
import ReuseProjectModal from "./reuseProjectModal";
import { DEVELOPER_PROJECT_STATUS } from "../../../../../commons/constants/developerProjStatus";

export default function CertificationLayout({ currentTab }) {
  const [isOpen, setOpen] = useState(false);
  const [isReuseProjModalOpen, setReuseProjModalOpen] = useState(false);
  const [invoicePlanSyscode, setInvoicePlanSyscode] = useState(null);
  const [certificationPlans, setCertificationPlans] = useState([]);
  const [developerPlan, setDeveloperPlan] = useState([]);
  const [devProj, setDevProject] = useState({});
  const [comingSoonDate, setComingSoonDate] = useState(null);
  const [industryApiOptions, setIndustryApiOptions] = useState([]);
  const [organisationApiOptions, setOrganisationApiOptions] = useState([]);
  const navigate = useNavigate();
  const ssoId = getUser()?.sso_id;
  const getStagingProjectsUrl =
    urls.baseUrl + commonConfigs.apiUrls.getProjects();
  const getCertificationPlansUrl =
    urls.resourceOwnerUrl + commonConfigs.apiUrls.getCertificationPlans();

  const handleComingSoonClick = (date) => {
    setComingSoonDate(date);
    setOpen(true);
  };

  const groupByCategory = (plans) => {
    return plans.reduce((acc, item) => {
      const catName = item.certification_category;
      if (!acc[catName]) {
        acc[catName] = [];
      }
      acc[catName].push(item);
      return acc;
    }, {});
  };

  const getBenefits = (industry) => {
    switch (industry) {
      case "Food Services":
        return [
          "Full Stack Developer Coursework",
          "OPENAPI PAAS Product, Voice & NETS Online Payment APIs",
          "Order-Fulfillment APIs",
        ];
      case "Air Transport":
        return [
          "Full Stack Developer Coursework",
          "OPENAPI PAAS Product, Voice & NETS Online Payment APIs",
          "Order-Fulfillment APIs",
          "Drone Traffic Management APIs",
        ];
      default:
        return [
          "Full Stack Developer Coursework",
          "OPENAPI PAAS Product, Voice & NETS Online Payment APIs",
          "Order-Fulfillment APIs",
        ];
    }
  };

  async function getStagingApisData() {
    await axios
      .get(getStagingProjectsUrl, { headers: commonConfigs.apiHeader })
      .then((res) => {
        // console.log(res.data.result.data);
        const projects = res.data.result.data;
        const industryApiOptions = projects.filter(
          (project) =>
            project.project_billing_config_id == 1 &&
            project.project_name.toLowerCase().includes("industry")
        );

        const organisationApiOptions = projects.filter(
          (project) =>
            project.project_billing_config_id == 1 &&
            !project.project_name.toLowerCase().includes("industry")
        );

        setIndustryApiOptions(industryApiOptions);
        setOrganisationApiOptions(organisationApiOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAllCertificationPlans() {
    await axios
      .get(getCertificationPlansUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        const groupedPlans = groupByCategory(res.data.result.data);
        setCertificationPlans(groupedPlans);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getDeveloperProjectByPlan(planId) {
    const getDeveloperProjectByPlanUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getDeveloperProjectByPlan(ssoId, planId);
    return await axios
      .get(getDeveloperProjectByPlanUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setDevProject(res.data.result.data);
        return res.data.result.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getStagingApisData().catch((err) => {
      console.log("err for staging projects: " + err);
    });
    getAllCertificationPlans().catch((err) => {
      console.log("err for certification plans: " + err);
    });
  }, []);

  const handleApplyClick = async (plan) => {
    setDeveloperPlan(plan);
    let environment;
    let invoicePlanSyscode;

    if (currentTab.name === "Certification") {
      const isGoLive =
        plan.certification_category.includes("Go Live");
      environment = isGoLive
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
      invoicePlanSyscode = isGoLive
        ? INVOICE_PLAN_SYSCODE.Certification_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.Certification_Plan_UAT;
    } else {
      const isProduction = currentTab.name !== "Staging";
      environment = isProduction
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
      invoicePlanSyscode = isProduction
        ? INVOICE_PLAN_SYSCODE.API_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.API_Plan_UAT;
    }
    setInvoicePlanSyscode(invoicePlanSyscode);
    const developerProject = await getDeveloperProjectByPlan(
      plan.developer_certification_plan_id
    ).catch((err) => {
      console.log("err for getting inactiveDevProject: " + err);
    });
    if (Object.keys(developerProject).length !== 0 && developerProject.developer_proj_status !== DEVELOPER_PROJECT_STATUS.Active) {
      setReuseProjModalOpen(true);
    } else {
      const projectNamesString = plan.project_name_preset;
      const projectNames = projectNamesString
        .split("|")
        .map((name) => name.toLowerCase());
      const industryApis = industryApiOptions
        .filter((project) => projectNames.includes(project.project_name))
        .map((project) => ({
          value: project.project_id,
          label: project.project_name,
        }));
      const organisationApis = organisationApiOptions
        .filter((project) => projectNames.includes(project.project_name))
        .map((project) => ({
          value: project.project_id,
          label: project.project_name
        }));

      const presetOptions = {
        environment: environment,
        invoicePlanSyscode: invoicePlanSyscode,
        industryApiOptions: industryApis,
        organisationApiOptions: organisationApis,
        subscriptionId: {
          value: plan.developer_certification_plan_id,
          label: plan.certification_plan,
        },
      };
      navigate("/projects/create", {
        state: { presetOptions: presetOptions },
      });
    }
  };

  return (
    <>
      <div
        className="text-center p-5 rounded-2xl relative mt-2"
        style={{
          backgroundColor: "#2b2d42",
          color: "white",
          borderRadius: "24px",
          margin: "0 auto",
          maxWidth: "90%",
        }}
      >
        <h1 className="mb-14" style={{ fontSize: "36px" }}>
          Sign up for CodeForeword Membership Today
        </h1>
      </div>

      <div
        className="flex justify-center items-center gap-x-36 relative"
        style={{ marginTop: "-60px" }}
      >
        <MembershipCard
          text="Enjoy 90% Discounts"
          header="Student Chapter Membership"
          icon={Student}
          onClick={() => handleComingSoonClick("End December")}
        />
        <MembershipCard
          text="Enjoy 50% Discounts"
          header="Organisation Membership"
          icon={Organisation}
          onClick={() => handleComingSoonClick("End December")}
        />
      </div>
      {Object.entries(certificationPlans).map(([category, plans]) => (
        <div key={category} className="category-section">
          <div className="mt-4 mb-8 text-left">
            <h1
              className="text-2xl font-bold text-black"
              style={{ fontSize: "30px" }}
            >
              {category}
            </h1>
          </div>
          <div className="flex items-start gap-8 mt-4">
            {plans.map((plan, index) => {
              return (
                <CourseCard
                  key={index}
                  plan={plan}
                  currentTab={currentTab}
                  industry={plan.industry}
                  price={plan.fixed_rate}
                  benefits={getBenefits(plan.industry)}
                  planId={plan.developer_certification_plan_id}
                  onButtonClick={() => handleApplyClick(plan)}
                />
              );
            })}
          </div>
        </div>
      ))}
      {isOpen && (
        <ComingSoonModal
          setOpenModal={setOpen}
          comingSoonDate={comingSoonDate}
        />
      )}
      {isReuseProjModalOpen && (
        <ReuseProjectModal
          setOpenModal={setReuseProjModalOpen}
          invoicePlanSyscode={invoicePlanSyscode}
          devProj={devProj}
          industryApiOptions={industryApiOptions}
          organisationApiOptions={organisationApiOptions}
          currentTab={currentTab}
          plan={developerPlan}
        />
      )}
    </>
  );
}
