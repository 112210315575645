import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import UsageProgress from "./usageProgress";
import Clock from "../../../../assets/clock.svg";

export default function UsageCard({ isTechSupport, data, width }) {
  const daysRemaining = (date) => {
    const days = Math.max(
      0,
      Math.ceil((new Date(date) - new Date()) / 86400000)
    );
    return `${days || 20} ${days === 1 ? "Day" : "Days"}`;
  };
  return (
    <Card
      sx={{
        marginBottom: "10px",
        backgroundColor: "rgba(0, 123, 255, 0.1)",
        width: width ? width : "100%",
      }}
    >
      <CardContent
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div className="md:flex md:items-center md:justify-between">
          <div
            className="text-sm text-black flex items-center space-x-2"
            style={{ marginBottom: "25px" }}
          >
            <img src={Clock} className="h-10 w-10" aria-hidden="true" />
            <div className="text-sm flex items-start flex-col space-y-1 text-left">
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "#007BFF",
                }}
              >
                {data?.plan_name || "IHL-NETS Certificate (UAT)"}
              </p>
              {isTechSupport && data?.type && (
                <p style={{ fontSize: "16px", color: "#007BFF" }}>
                  {data.type}
                </p>
              )}
            </div>
          </div>
          <div
            className="text-right"
            style={{ color: "#007BFF", marginBottom: "25px" }}
          >
            <p className="font-bold font-xl">{daysRemaining(data?.expiry)}</p>
            <p>Remaining</p>
          </div>
        </div>

        {/* usage bar here */}
        <UsageProgress
          startDate={data?.start_datetime || "2024-12-20 05:15:56.000"}
          endDate={data?.expiry || "2025-12-19 05:15:56.000"}
        />

        {/* details of expiry here */}
        <div style={{ marginTop: "auto", textAlign: "left" }}>
          <p style={{ fontSize: "17px", color: "#007BFF" }}>
            Expires on: {data?.expiry || "01.04.2023 23.59"}
          </p>
        </div>
        {isTechSupport && (
          <button
            data-inline="true"
            type="button"
            className="mt-4 w-full bg-white hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 inline-flex justify-center rounded-full border border-transparent shadow-sm px-12 py-2 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm"
          >
            Change Plan
          </button>
        )}
      </CardContent>
    </Card>
  );
}
