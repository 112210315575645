export const INVOICE_STATUS = {
  PENDING: 0,
  IN_PROGRESS: 1,
  PAID: 2,
  OVERDUE: 3
}

export const InvoiceSyscodeString = {
  [INVOICE_STATUS.PENDING]: "Pending",
  [INVOICE_STATUS.IN_PROGRESS]: "In Progress",
  [INVOICE_STATUS.PAID]: "Paid",
  [INVOICE_STATUS.OVERDUE]: "Overdue"
};
