import React from "react";

import PaymentLayout from "./paymentLayout";

const PaymentPage = () => {
  return (
    <>
      <PaymentLayout />
    </>
  );
};
  
export default PaymentPage;