import { INVOICE_STATUS, InvoiceSyscodeString } from "../../../../commons/constants/invoiceStatusTypeSyscode.jsx";

export const InvoiceStatus = ({ value }) => {
  const statusText = InvoiceSyscodeString[value];

  const getStatusStyle = (status) => {
    switch (status) {
      case INVOICE_STATUS.PAID:
        return { backgroundColor: "#55FF00", color: "black" }; // Green
      case INVOICE_STATUS.OVERDUE:
        return { backgroundColor: "#FF0000", color: "black" }; // Red
      case INVOICE_STATUS.PENDING:
        return { backgroundColor: "#FF9D00", color: "black" }; // Orange
    }
  };
  const style = {
    ...getStatusStyle(value),
    padding: "5px",
    borderRadius: "15px",
    margin: "5px",
    fontWeight: "500",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "90px",
    textAlign: "center", 
    display: "inline-flex", 
    justifyContent: "center", 
    alignItems: "center", 
  };

  return <span style={style}>{statusText}</span>;
};
