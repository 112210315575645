import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import Spinner from "../../../../../commons/components/spinner";
import Table from "../../../../../commons/components/table";
import { INVOICE_PLAN_SYSCODE } from "../../../../../commons/constants/invoicePlanTypeSyscode";
import { commonConfigs, urls } from "../../../../../config/config";
import { getUser } from "../../../../../services/tokenService";
import { InvoiceStatus } from "../../components/invoiceStatus";
import UsageCard from "../../components/usageCard";
import { INVOICE_STATUS } from "../../../../../commons/constants/invoiceStatusTypeSyscode";

export default function CertificationInvoice() {
  const [load, setLoad] = useState(true);
  const [invoicesData, setInvoicesData] = useState();
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [plans, setPlans] = useState();
  const accountId = getUser()?.account_id;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;

  var StartDateData = sessionStorage.getItem("startdate");
  var EndDateData = sessionStorage.getItem("enddate");

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);

  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate("");
    setEndDate("");
    sessionStorage.setItem("startdate", "");
    sessionStorage.setItem("enddate", "");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    StartDateData.length > 0
      ? setStartDateFilter(StartDateData)
      : setStartDateFilter(startdate);
    EndDateData.length > 0
      ? setEndDateFilter(EndDateData)
      : setEndDateFilter(startdate);
  };

  async function getCertificationPlans() {
    const getCertificationPlansUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getCertificationPlans();
    await axios.get(getCertificationPlansUrl, {
      headers: commonConfigs.resourceOwnerApiHeader
    })
    .then((res) => {
      setPlans(res.data.result.data);
      getDeveloperCourse(res.data.result.data).catch((err) => {
        throw err;
      });
    })
    .catch((err) => {
      console.log(err);
    })
  }

  async function getCertificationInvoices() {
    const getInvoicesUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getInvoices(
        accountId,
        INVOICE_PLAN_SYSCODE.Certification_Plan_UAT,
        startdateFilter,
        enddateFilter,
        "",
        "",
        ""
      );
    await axios.get(getInvoicesUrl, {
      headers: commonConfigs.resourceOwnerApiHeader
    })
    .then((res) => {
      // console.log(res.data.result.data);
      const paidInvoices = res.data.result.data.filter(invoice => invoice.invoice_status === INVOICE_STATUS.PAID);
      setInvoicesData(paidInvoices);
      setIsApplying(false);
      setIsReseting(false);
      setLoad(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  async function getDeveloperCourse(certPlans) {
    const getDeveloperCourseUrl = 
      urls.resourceOwnerUrl + commonConfigs.apiUrls.getDeveloperCourses(accountId, '', 0)

    await axios.get(getDeveloperCourseUrl, {
      headers: commonConfigs.resourceOwnerApiHeader
    })
    .then((res) => {
      let ongoingCourse = [];
      if (Array.isArray(res.data.result.data)) {
        res.data.result.data.map(course => {
          const match = certPlans.find(plan => plan.developer_certification_plan_id === course.invoice_plan_id)
          ongoingCourse.push({
            ...course,
            expiry: course.course_expiry,
            start_datetime: course.course_start_datetime,
            plan_name: match.certification_plan
          })
        })
      } else {
        const match = certPlans.find(plan => plan.developer_certification_plan_id === res.data.result.data.invoice_plan_id);
        ongoingCourse.push({
          ...course,
          expiry: course.course_expiry,
          start_datetime: course.course_start_datetime,
          plan_name: match.certification_plan
        })
      }
      setOngoingCourses(ongoingCourse)
    })
    .catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    if (load) {
      getCertificationInvoices().catch((err) => {
        console.log("err: " + err);
      });
      getCertificationPlans().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load, startdateFilter, enddateFilter]);

  const columns = useMemo(() => [
    {
      Header: "Invoice No.",
      accessor: "txn_invoice_id",
      // Cell: (row) => row.row.index + 1,
    },
    {
      Header: "Plan Name",
      accessor: "invoice_plan_id", 
      Cell: ({ value }) => {
        const match = plans.find(plan => plan.developer_certification_plan_id === value);
        return match.certification_plan; 
      },
    },
    {
      Header: "Billing Period",
      accessor: "invoice_billing_period",
      Cell: ({ row }) => {
        const { invoice_start_datetime, invoice_end_datetime } = row.original;
        const startDate = new Date(invoice_start_datetime)
          .toISOString()
          .split("T")[0];
        const endDate = new Date(invoice_end_datetime)
          .toISOString()
          .split("T")[0];
        return `${startDate} to ${endDate}`;
      },
    },
    {
      Header: "Paid Date",
      accessor: "invoice_paid_datetime",
      Cell: ({ value }) => {
        const paidDate = new Date(value)
          .toISOString()
          .split("T")[0];
        return paidDate ? paidDate : '-';
      },
    },
    {
      Header: "Subtotal Amount",
      accessor: "invoice_sub_total_amt",
    },
    {
      Header: "Coursework Code",
      accessor: "course_code",
      Cell: ({ row }) => {
        const { voucher_code, voucher_syscode } = row.original;
        return voucher_syscode && (voucher_syscode === 6 || 7) ? voucher_code : "-";
      },
    },
    {
      Header: "Voucher Code",
      accessor: "voucher_code",
      Cell: ({ row }) => {
        const { voucher_code, voucher_syscode } = row.original;
        return voucher_syscode && !(voucher_syscode === 6 || 7) ? voucher_code : "-";
      },
    },
    {
      Header: "Voucher Amount",
      accessor: "voucher_amt",
    },
    {
      Header: "Discount Percent",
      accessor: "discount_percent",
    },
    {
      Header: "Discount Amount",
      accessor: "discount_amt",
    },
    {
      Header: "Total Nett Amount",
      accessor: "invoice_total_nett_amt",
    },
    {
      Header: "Status",
      accessor: "invoice_status",
      Cell: ({ cell: { value } }) => <InvoiceStatus value={value} />,
    },
  ]);

  return (
    <>
      <div
        className="mb-2 flex flex-grow gap-x-5 justify-end"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${Math.min(ongoingCourses.length, 3)}, 3fr)`,
        }}
      >
        {ongoingCourses.map((course) => {
          return (
            <UsageCard data={course} />
          )
        })}
      </div>
      <div
        style={{
          backgroundColor: "#FFF",
          padding: "20px",
          borderRadius: "10px",
          margin: "20px 0 20px 0 ",
          textAlign: "left",
          color: "black",
        }}
      >
        <row style={{ display: "flex" }}>
          <div style={{ width: "100%", padding: "10px" }}>
            <div
              className="font-bold"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <h6 style={{ marginBottom: "0px" }}>Billing Period Start Date</h6>
            </div>
            <input
              type="date"
              style={{ width: "100%", padding: "10px", borderRadius: "8px" }}
              value={startdate}
              onChange={(e) => {
                setStartDate(e.target.value);
                sessionStorage.setItem("startdate", e.target.value);
              }}
            />
          </div>
          <div style={{ width: "100%", padding: "10px" }}>
            <div
              className="font-bold"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <h6 style={{ marginBottom: "0px" }}> Billing Period End Date </h6>
            </div>
            <input
              type="date"
              style={{ width: "100%", padding: "10px", borderRadius: "8px" }}
              value={enddate}
              onChange={(e) => {
                setEndDate(e.target.value);
                sessionStorage.setItem("enddate", e.target.value);
              }}
            />
          </div>
        </row>
        <div style={{ textAlign: "end", paddingTop: "34px" }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-12 py-2 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500"
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "Apply"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-12 py-2 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting
                ? "bg-red-500 text-white cursor-not-allowed"
                : "hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500"
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>
      </div>

      {/* table */}
      {invoicesData != null ? (
        <div>
          <Table
            columns={columns}
            data={invoicesData}
            filters={columns.map((column) => column.accessor)}
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </>
  );
}
