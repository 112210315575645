import React from "react";

import TxnNetsFailStatusLayout from "./txnNetsFailStatusLayout";

const TxnNetsFailStatusPage = () => {
  return (
    <>
      <TxnNetsFailStatusLayout />
    </>
  );
};

export default TxnNetsFailStatusPage;