import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const UsageProgress = ({ startDate, endDate }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      const now = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (now <= start) return 0; 
      if (now >= end) return 100; 

      const totalDuration = end - start;
      const elapsed = now - start;
      return Math.min((elapsed / totalDuration) * 100, 100); 
    };
    let progressNum = Math.floor(100 - calculateProgress());
    if (progressNum == 0) progressNum = 100
    setProgress(progressNum);
  }, [startDate, endDate]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default UsageProgress;