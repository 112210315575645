export const DEVELOPER_PROJECT_STATUS = {
  Inactive: 0,
  Active: 1,
  Suspended: 2,
  Expired: 3
}

export const DeveloperProjectStatusString = {
  [DEVELOPER_PROJECT_STATUS.Inactive]: "Inactive",
  [DEVELOPER_PROJECT_STATUS.Active]: "Active",
  [DEVELOPER_PROJECT_STATUS.Suspended]: "Suspended",
  [DEVELOPER_PROJECT_STATUS.Expired]: "Expired"
};