import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ReuseProjectModal({
    setOpenModal,
    devProj,
    industryApiOptions,
    organisationApiOptions,
    currentTab,
    plan
  }) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  function handleEdit() {
    const projectNamesString = plan.project_name_preset;
    const projectNames = projectNamesString
      .split("|")
      .map((name) => name.toLowerCase());
    const industryApis = industryApiOptions
      .filter((project) => projectNames.includes(project.project_name))
      .map((project) => ({
        value: project.project_id,
        label: project.project_name,
      }));
    const organisationApis = organisationApiOptions
      .filter((project) => projectNames.includes(project.project_name))
      .map((project) => ({
        value: project.project_id,
        label: project.project_name,
      }));

    let environment;

    if (currentTab.name === "Certification") {
      const isGoLive =
        plan.certification_category.includes("Go Live");
      environment = isGoLive
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
    } else {
      const isProduction = currentTab.name !== "Staging";
      environment = isProduction
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
    }
    const presetOptions = {
      environment: environment,
      invoicePlanSyscode: devProj.invoice_plan_syscode,
      industryApiOptions: industryApis,
      organisationApiOptions: organisationApis,
      subscriptionId: {
        value: plan.developer_certification_plan_id,
        label: plan.certification_plan,
      },
      developerProjName: devProj.developer_proj_name,
      developerProjId: devProj.developer_proj_id,
      platformSyscode: devProj.platform_syscode,
      websiteUrl: devProj.developer_proj_website_url,
    };
    navigate("/projects/create", {state: { presetOptions: presetOptions }});
  }
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
                onClick={() => setOpenModal(false)}
              />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-3"
                      style={{
                        marginBottom: "25px",
                        marginTop: "-20px",
                        fontSize: "20px",
                      }}
                    >
                      Project Found for This Course
                    </Dialog.Title>
                    <div className="mt-2 text-black" style={{ fontSize: "19" }}>
                      <p className="block text-sm font-medium ">
                        We found an existing project linked to this course:
                      </p>
                      <ul>
                        <li>
                          <strong>Project Name: </strong>
                          {devProj.developer_proj_name}
                        </li>
                        <li>
                          <strong>Status: </strong>
                          {devProj.developer_proj_status_type}
                        </li>
                      </ul>
                      <p className="mt-2">How would you like to proceed?</p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex justify-center rounded-md px-4 py-2 hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500 font-bold sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center border-transparent rounded-md px-4 py-2 border-none hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 font-bold sm:col-start-2 sm:text-sm"
                    style={{ marginTop: "16px" }}
                    onClick={() => handleEdit()}
                  >
                    Proceed with Payment
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
