import axios from "axios";
import { format } from "date-fns";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DEVELOPER_PROJECT_STATUS } from "../../../commons/constants/developerProjStatus";
import { getDoublewriteConfig } from "../../../commons/utils/getDoublewriteConfig";
import { commonConfigs, urls } from "../../../config/config";
import { getUser } from "../../../services/tokenService";

const ProjectCard = ({ data, environment }) => {
  const accountId = getUser()?.account_id;

  async function getDeveloperCourse(planId, projId) {
    const getDeveloperCourseUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getDeveloperCourses(accountId, planId, 0);
    await axios
      .get(getDeveloperCourseUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        // console.log(res.data.result.data);
        if (Object.keys(res.data.result.data).length !== 0) {
        } else { // Project expired
          await updateDeveloperProj(projId);
          await updateDoublewriteDevProj(projId);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  
  async function updateDoublewriteDevProj(projId) {
    const { url, headers } = getDoublewriteConfig(process.env.REACT_APP_ENV);
    const doublewriteUrl =
      url + commonConfigs.apiUrls.UpdateDeveloperProject(projId);
    const body = {
      developer_proj_status: DEVELOPER_PROJECT_STATUS.Expired,
    };
    try {
      await axios.put(doublewriteUrl, body, { headers: headers });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }

  async function updateDeveloperProj(projId) {
    const updateProjUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.UpdateDeveloperProject(projId);
    const body = {
      developer_proj_status: DEVELOPER_PROJECT_STATUS.Expired,
    };
    try {
      await axios.put(updateProjUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }

  useEffect(()=>{
    if (environment === "staging") {
      getDeveloperCourse(data.invoice_plan_id || data.access[0].invoice_plan_id, data.developer_proj_id).catch((err) => {
        console.log("err for getting developer courses: " + err);
      });
    }
  },[])

  const renderEnabledAPIs = () => {
    const projects = data.access
      .filter((project) => project.project_name !== "beveatshop")
      .map((project) => project.project_name);
    if (projects.length === 1) {
      return projects[0];
    } else {
      return projects.join(" | ");
    }
  };

  return (
    <div
      className="bg-white rounded-2xl"
      style={{
        height: "inherit",
        width: "100%",
        minHeight: 0,
        minWidth: 0,
        boxShadow: "5px 10px #EBECF0",
        marginTop: "20px",
        borderWidth: "0.5px",
        borderColor: "black",
        borderStyle: "solid",
        fontSize: "16px",
      }}
    >
      <div
        className="p-3"
        style={{
          display: "grid",
          gridTemplateAreas: `'text1 text1 text1' 'text2 text2 text2''text3 text3 text3''text4 text4 text4''button1 button1 button1'`,
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: 4,
          maxWidth: "inherit",
          color: "black",
        }}
      >
        {/* header */}
        <div
          className="font-bold"
          style={{ gridArea: "text1", textAlign: "left" }}
        >
          {data.developer_proj_name}
        </div>

        {/* details */}
        <div className="pt-10" style={{ gridArea: "text2", textAlign: "left" }}>
          Status: {data.developer_proj_status_type}
        </div>

        <div className="pt-10" style={{ gridArea: "text3", textAlign: "left" }}>
          {renderEnabledAPIs()}
        </div>

        {/* last update */}
        <div
          className="pt-4 text-gray-700"
          style={{ gridArea: "text4", textAlign: "left" }}
        >
          Created on:
          {format(new Date(data.created_on), "dd MMM yyyy HH:mm")}
        </div>

        {/* buttons */}
        <div style={{ gridArea: "button1", marginBottom: "-35px" }}>
          <Link
            to={`/projects/details`}
            state={{
              developerSsoId: data.developer_sso_id,
              developerProjId: data.developer_proj_id,
              environment: environment,
            }}
          >
            <button
              type="button"
              className="hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500 py-2 px-12 rounded-full text-sm mt-8 mb-5"
              style={{ textAlign: "center", width: "80%" }}
            >
              View More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
