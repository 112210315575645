export const ssoUrl = `${process.env.REACT_APP_SSO_OPENAPIPAAS_URL}`;
const resourceDomain = localStorage.getItem("resourceDomain");
const getEnvVariable = (key) => process.env[key];

let urls = {
  baseUrl: getEnvVariable(
    `REACT_APP_BASE_${resourceDomain?.toUpperCase()}_STAGING_URL`
  ),
  prodUrl: getEnvVariable(
    `REACT_APP_BASE_${resourceDomain?.toUpperCase()}_PRODUCTION_URL`
  ),
  sandboxUrl: getEnvVariable(
    `REACT_APP_BASE_${resourceDomain?.toUpperCase()}_SANDBOX_URL`
  ),
  resourceOwnerUrl: getEnvVariable(
    `REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_URL`
  ),
};

let environmentUrls = {
  staging: urls.baseUrl,
  sandbox: urls.sandboxUrl,
  production: urls.prodUrl,
};

let commonConfigs = {
  ssoHeader: {
    "api-key": getEnvVariable(
      `REACT_APP_SSO_${resourceDomain?.toUpperCase()}_API_KEY`
    ),
    "project-id": getEnvVariable(
      `REACT_APP_SSO_${resourceDomain?.toUpperCase()}_PROJECT_ID`
    ),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE,
  },
  apiHeader: {
    "api-key": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_API_KEY`
    ),
    "project-id": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_PROJECT_ID`
    ),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE,
  },
  sandboxApiHeader: {
    "api-key": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_API_KEY`
    ),
    "project-id": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_PROJECT_ID`
    ),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE,
  },
  prodApiHeader: {
    "api-key": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_API_KEY`
    ),
    "project-id": getEnvVariable(
      `REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_PROJECT_ID`
    ),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE,
  },
  resourceOwnerApiHeader: {
    "api-key": getEnvVariable(
      `REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_API_KEY`
    ),
    "project-id": getEnvVariable(
      `REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_PROJECT_ID`
    ),
    "platform-syscode": process.env.REACT_APP_PLATFORM_SYSCODE,
  },

  apiUrls: {
    ssoUserAuth: () => `${ssoUrl}/auth/users`,
    ssoAdminAuth: () => `${ssoUrl}/auth/business`,
    login: () => `/web/auth/login`,
    refreshAccessToken: () => `/web/auth/token/refresh`,

    //Staging Developer Projects Apis
    GetAllDeveloperProjects: (developer_sso_id, environment) =>
      `/developers/${developer_sso_id}/projects?environment=${environment}`,
    GetOneDeveloperProjects: (developer_sso_id, developer_proj_id) =>
      `/developers/${developer_sso_id}/projects/${developer_proj_id}`,
    CreateDeveloperProject: () => `/developers/projects`,
    doublewriteDeveloperProject: () => `/developers/projects/doublewrite`,
    UpdateDeveloperProject: (developer_proj_id) =>
      `/developers/projects/${developer_proj_id}`,
    getProjects: () => `/resource-group/projects`,
    getDeveloperProjectByPlan: (developer_sso_id, invoice_plan_id) =>
      `/developers/${developer_sso_id}/projects?invoice_plan_id=${invoice_plan_id}`,

    //Portfolio Apis
    getAllPortfolioProjects: (mobileNum) =>
      `/developers/portfolios/mobile/${mobileNum}`,

    // Access Pages Apis
    getAllAccessPages: () => `/developers/access`,
    createAccessPage: () => `/developers/access`,
    updateAccessPage: (access_id) => `/developers/access/${access_id}`,
    deleteAccessPage: (access_id) => `/developers/access/${access_id}`,

    getVoucher: (voucher_code) =>
      `/common/org/${process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID}/vouchers?code=${voucher_code}`,

    validateVoucher: (
      account_id,
      voucher_code,
      developer_certification_plan_id
    ) =>
      `/common/org/${process.env.REACT_APP_RESOURCE_OPENAPIPAAS_ORG_ID}/vouchers/developers/${account_id}?voucher_code=${voucher_code}&developer_certification_plan_id=${developer_certification_plan_id}`,

    getCertificationPlans: () => `/developers/plans/certifications`,
    getProductionPlans: () => `/developers/plans/production`,
    getStagingPlans: () => `/developers/plans/staging`,

    getAllDeveloperCertificates: (account_id) =>
      `/developers/certifications/${account_id}`,

    validateNetsQrTxn: (retrieval_ref, txn_id) =>
      `/developers/certifications/nets-qr?txn_retrieval_ref=${retrieval_ref}&txn_id=${txn_id}`,

    validateNetsClickTxn: (retrieval_ref, txn_id) =>
      `/developers/certifications/nets-click?txn_retrieval_ref=${retrieval_ref}&txn_id=${txn_id}`,

    validateEnetsTxn: (retrieval_ref, txn_id) =>
      `/developers/certifications/enets?txn_retrieval_ref=${retrieval_ref}&txn_id=${txn_id}`,

    createInvoice: () => `/transactions/invoices`,
    updateInvoice: (txn_invoice_id) => `/transactions/invoices/${txn_invoice_id}/status`,

    getInvoices: (
      account_id,
      invoice_plan_syscode,
      invoice_start_date,
      invoice_end_date,
      invoice_status,
      developer_proj_id,
      project_id
    ) =>
      `/transactions/invoices?account_id=${account_id}&invoice_plan_syscode=${invoice_plan_syscode}&invoice_start_date=${invoice_start_date}&invoice_end_date=${invoice_end_date}&invoice_status=${invoice_status}&developer_proj_id=${developer_proj_id}&project_id=${project_id}`,
    createDeveloperCourse: (account_id) => `/developers/${account_id}/courses`,
    getDeveloperCourses: (account_id, invoice_plan_id, is_expired) =>
      `/developers/${account_id}/courses?invoice_plan_id=${invoice_plan_id}&is_expired=${is_expired}`,

    requestNetsApi: () => `/common/payments/nets-qr/request`,
    queryNetsApi: () => `/common/payments/nets-qr/query`,
    webhookNetsApi: (txnRetrievalRef) =>
      `/common/payments/nets/webhook?txn_retrieval_ref=${txnRetrievalRef}`,

    createCertificate: () => `/developers/certifications`,

    updateCertificate: () => `/developers/certifications`,

    // Access Roles Apis
    getAllAccessRoles: () => `/developers/roles`,
    createAccessRole: () => `/developers/roles`,
    updateAccessRole: (role_id) => `/developers/roles/${role_id}`,
    deleteAccessRole: (role_id) => `/developers/roles/${role_id}`,

    // Configurations Apis
    // GetAllBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // // `http://localhost:8080/api/v1/resource-groups/projects/billing-config`,
    // GetOneBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // CreateBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // UpdateBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // DeleteBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,

    // GetAllSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // // `http://localhost:8080/api/v1/resource-groups/subscriptions`,
    // GetOneSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // CreateSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // UpdateSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // DeleteSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
  },
};

let environmentHeaders = {
  staging: commonConfigs.apiHeader,
  sandbox: commonConfigs.sandboxApiHeader,
  production: commonConfigs.prodApiHeader,
};

export { urls, environmentUrls, commonConfigs, environmentHeaders };
