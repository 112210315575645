import { Link, useNavigate } from "react-router-dom";
import { getUser } from "../../../../../services/tokenService";
import axios from "axios";
import { useEffect, useState } from "react";
import { urls, commonConfigs } from "../../../../../config/config";
import CreateCertificateDetails from "./createCertificateDetailsModal";
import { INVOICE_PLAN_SYSCODE } from "../../../../../commons/constants/invoicePlanTypeSyscode";
import { DEVELOPER_PROJECT_STATUS } from "../../../../../commons/constants/developerProjStatus";
import { getDoublewriteConfig } from "../../../../../commons/utils/getDoublewriteConfig";

export default function CourseCard({
  industry,
  plan,
  currentTab,
  price,
  planId,
  benefits,
  onButtonClick,
}) {
  const [load, setLoad] = useState(true);
  const [certificates, setCertificates] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [project, setProject] = useState();
  const [invoicePlanSyscode, setInvoicePlanSyscode] = useState(null);
  const navigate = useNavigate();
  const ssoId = getUser()?.sso_id;
  const accountId = getUser()?.account_id;
  const getDeveloperCertificatesUrl =
    urls.resourceOwnerUrl +
    commonConfigs.apiUrls.getAllDeveloperCertificates(accountId);

  async function getDeveloperProjectByPlan(planId) {
    const getDeveloperProjectByPlanUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getDeveloperProjectByPlan(ssoId, planId);
    await axios
      .get(getDeveloperProjectByPlanUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        if (res.data.result.data) {
          setProject(res.data.result.data);
          if (
            res.data.result.data.developer_proj_status ===
            DEVELOPER_PROJECT_STATUS.Active
          ) {
            await getDeveloperCourse(
              planId,
              res.data.result.data.developer_proj_id
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateDoublewriteDevProj(projId) {
    const { url, headers } = getDoublewriteConfig(process.env.REACT_APP_ENV);
    const doublewriteUrl =
      url + commonConfigs.apiUrls.UpdateDeveloperProject(projId);
    const body = {
      developer_proj_status: DEVELOPER_PROJECT_STATUS.Expired,
    };
    try {
      await axios.put(doublewriteUrl, body, { headers: headers });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }

  async function updateDeveloperProj(projId) {
    const updateProjUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.UpdateDeveloperProject(projId);
    const body = {
      developer_proj_status: DEVELOPER_PROJECT_STATUS.Expired,
    };
    try {
      await axios.put(updateProjUrl, body, {
        headers: commonConfigs.resourceOwnerApiHeader,
      });
    } catch (error) {
      console.error("Error updating doublewrite project:", error);
    }
  }

  async function getDeveloperCourse(planId, projId) {
    const getDeveloperCourseUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getDeveloperCourses(accountId, planId, 0);
    await axios
      .get(getDeveloperCourseUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then(async (res) => {
        // console.log(res.data.result.data);
        if (Object.keys(res.data.result.data).length !== 0) {
          // If course found, show details
          setShowDetails(true);
        } else {
          // Project expired
          await updateDeveloperProj(projId);
          await updateDoublewriteDevProj(projId);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async function getDeveloperCertificates() {
    await axios
      .get(getDeveloperCertificatesUrl, {
        headers: commonConfigs.resourceOwnerApiHeader,
      })
      .then((res) => {
        // console.log(res.data.result.data);
        setCertificates(res.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getDeveloperCertificates().catch((err) => {
      console.log("err for getting developer certificates: " + err);
    });
    let invoicePlanSyscode;
    if (currentTab.name === "Certification") {
      const isGoLive =
        plan.certification_category.includes("Go Live");
      invoicePlanSyscode = isGoLive
        ? INVOICE_PLAN_SYSCODE.Certification_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.Certification_Plan_UAT;
    } else {
      const isProduction = currentTab.name !== "Staging";
      environment = isProduction
        ? { value: "1", label: "Production" }
        : { value: "0", label: "Staging" };
      invoicePlanSyscode = isProduction
        ? INVOICE_PLAN_SYSCODE.API_Plan_Go_Live
        : INVOICE_PLAN_SYSCODE.API_Plan_UAT;
    }
    getDeveloperProjectByPlan(planId).catch((err) => {
      console.log("err for getting inactiveDevProject: " + err);
    });
    setInvoicePlanSyscode(invoicePlanSyscode);
    setLoad(false);
  }, []);

  const onGetCertificateClick = () => {
    const developerCertificate = certificates.find(
      (certificate) =>
        certificate.invoice_plan_id == planId &&
        certificate.developer_proj_id == project.developer_proj_id
    );
    if (developerCertificate) {
      navigate("/subscriptions/certification", {
        state: {
          planName: plan.certification_plan,
          certificationPlanId: planId,
          environment: "staging",
          devProjId: project.developer_proj_id,
        },
      });
    } else {
      setOpen(true);
    }
  };
  if (!load) {
    return (
      <>
        <div className="bg-white shadow-lg rounded-lg w-80 h-[26rem] flex flex-col justify-between">
          <div className="p-6 text-black">
            <h2
              className="text-gray-700 font-bold mb-2"
              style={{ fontSize: "25px" }}
            >
              Industry
            </h2>
            <div className="bg-blue-400 text-white font-bold text-lg py-1 rounded-full mb-2">
              {industry}
            </div>
            <p className="text-3xl font-bold text-gray-800 mb-1">
              <span className="line-through text-black">${price}</span>
            </p>
            <p className="text-red-600 text-sm mb-4">with Course Code</p>
            <ul
              className="text-left"
              style={{ listStyleType: "circle", paddingLeft: "20px" }}
            >
              {benefits.map((benefit, index) => (
                <li key={index} className="mb-2">
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div>
            {showDetails ? (
              <div className="flex border-t border-white">
                <Link
                  to={`/projects/details`}
                  className="flex-1 bg-blue-400 text-white py-3 font-semibold hover:bg-blue-600"
                  state={{
                    developerSsoId: ssoId,
                    developerProjId: project.developer_proj_id,
                    environment: "staging",
                  }}
                >
                  <button>View Project Details</button>
                </Link>
                <div className="w-[1px] bg-white"></div>
                <button
                  onClick={onGetCertificateClick}
                  className="flex-1 bg-blue-400 text-white py-3 font-semibold hover:bg-blue-600"
                >
                  Get Course Certificate
                </button>
              </div>
            ) : (
              <button
                onClick={onButtonClick}
                className="bg-blue-400 text-white py-3 px-4 w-full font-semibold hover:bg-blue-600"
              >
                Apply Course Code
              </button>
            )}
          </div>
          {isOpen && (
            <CreateCertificateDetails
              setOpenModal={setOpen}
              invoicePlanSyscode={invoicePlanSyscode}
              planName={plan.certification_category.certification_category}
              planId={planId}
              devProjId={project.developer_proj_id}
              environment={"staging"}
            />
          )}
        </div>
      </>
    );
  }
}
