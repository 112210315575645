import { createContext, useState } from "react";

const tabs = [
    { name: "Certification", value: 0 },
    { name: "Staging", value: 1 },
    { name: "Production", value: 2 },
    { name: "Tech Support", value: 3 },
    { name: "Membership", value: 4 },
  ];

export const invoiceTabsContext = createContext();
export const invoiceCurrentTabContext = createContext();
export const InvoiceContextProvider = (props) => {
  const [currentTab, setTab] = useState(tabs[0]);
  const setTabByValue = (value) => {
    const matchingTab = tabs.find((tab) => tab.value === value);
    if (matchingTab) {
      setTab(matchingTab);
    }
  };
  const contextValue = { currentTab, setTab, setTabByValue };
  return (
    <invoiceTabsContext.Provider value={{ tabs }}>
      <invoiceCurrentTabContext.Provider value={contextValue}>
        {props.children}
      </invoiceCurrentTabContext.Provider>
    </invoiceTabsContext.Provider>
  );
};
