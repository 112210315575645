import React, { useEffect, useMemo, useState } from "react";
import Spinner from "../../../../../commons/components/spinner";
import Table from "../../../../../commons/components/table";
import { InvoiceStatus } from "../invoiceStatus";
import { INVOICE_STATUS } from "../../../../../commons/constants/invoiceStatusTypeSyscode";
import { INVOICE_PLAN_SYSCODE } from "../../../../../commons/constants/invoicePlanTypeSyscode";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../../../services/tokenService";
import { commonConfigs, urls } from "../../../../../config/config";
import axios from "axios";

export default function ProductionInvoice() {
  const [load, setLoad] = useState(true);
  const [selectedRowDetailArr, setSelectedRowDetailArr] = useState(null);
  const [invoicesData, setInvoicesData] = useState();
  const [selectedRowProjectArr, setSelectedRowProjectArr] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const accountId = getUser()?.account_id;
  const ssoId = getUser()?.sso_id;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1 to get the current month.

  // Calculate the first day of the current month.
  const firstDayOfMonth = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-01`;

  // Calculate the last day of the current month.
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonthFormatted = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;

  var StartDateData = sessionStorage.getItem("startdate");
  var EndDateData = sessionStorage.getItem("enddate");

  const [isApplying, setIsApplying] = useState(false);
  const [isReseting, setIsReseting] = useState(false);

  const [startdate, setStartDate] = useState(firstDayOfMonth);
  const [enddate, setEndDate] = useState(lastDayOfMonthFormatted);

  const [startdateFilter, setStartDateFilter] = useState(startdate);
  const [enddateFilter, setEndDateFilter] = useState(enddate);

  const resetFilter = () => {
    setIsReseting(true);
    setLoad(true);
    setStartDate("");
    setEndDate("");
    sessionStorage.setItem("startdate", "");
    sessionStorage.setItem("enddate", "");
    setStartDateFilter("");
    setEndDateFilter("");
  };

  const applyFilter = () => {
    setIsApplying(true); // Disable the "APPLY" button
    setLoad(true);
    StartDateData.length > 0
      ? setStartDateFilter(StartDateData)
      : setStartDateFilter(startdate);
    EndDateData.length > 0
      ? setEndDateFilter(EndDateData)
      : setEndDateFilter(startdate);
  };

  async function getProductionInvoices() {
    const getInvoicesUrl =
      urls.resourceOwnerUrl +
      commonConfigs.apiUrls.getInvoices(
        accountId,
        INVOICE_PLAN_SYSCODE.API_Plan_Go_Live,
        startdateFilter,
        enddateFilter,
        "",
        "",
        ""
      );
    await axios.get(getInvoicesUrl, {
      headers: commonConfigs.resourceOwnerApiHeader
    })
    .then((res) => {
      setInvoicesData(res.data.result.data);
      setIsApplying(false);
      setIsReseting(false);
      setLoad(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if (load) {
      getProductionInvoices().catch((err) => {
        console.log("err: " + err);
      });
    }
  }, [load, startdateFilter, enddateFilter]);

  const columns = React.useMemo(() => [
    {
      Header: "Invoice No.",
      accessor: "txn_invoice_id",
      // Cell: (row) => row.row.index + 1,
    },
    {
      Header: "Billing Period",
      accessor: "invoice_billing_period",
      Cell: ({ row }) => {
        const { invoice_start_datetime, invoice_end_datetime } = row.original;
        const startDate = new Date(invoice_start_datetime)
          .toISOString()
          .split("T")[0];
        const endDate = new Date(invoice_end_datetime)
          .toISOString()
          .split("T")[0];
        return `${startDate} to ${endDate}`;
      },
    },
    {
      Header: "Due Date",
      accessor: "invoice_due_datetime",
    },
    {
      Header: "Paid Date",
      accessor: "invoice_paid_datetime",
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Subtotal Amount",
      accessor: "invoice_sub_total_amt",
    },
    {
      Header: "Coursework Code",
      accessor: "course_code",
      Cell: ({ row }) => {
        const { voucher_code, voucher_syscode } = row.original;
        return voucher_syscode && (voucher_syscode === 6 || 7) ? voucher_code : "-";
      },
    },
    {
      Header: "Voucher Code",
      accessor: "voucher_code",
      Cell: ({ row }) => {
        const { voucher_code, voucher_type } = row.original;
        return voucher_type && !(voucher_type === 6 || 7) ? voucher_code : "-";
      },
    },
    {
      Header: "Voucher Amount",
      accessor: "voucher_amt",
    },
    {
      Header: "Discount Percent",
      accessor: "discount_percent",
    },
    {
      Header: "Discount Amount",
      accessor: "discount_amt",
    },
    {
      Header: "Total Nett Amount",
      accessor: "invoice_total_nett_amt",
    },
    {
      Header: "Status",
      accessor: "invoice_status",
      Cell: ({ cell: { value } }) => <InvoiceStatus value={value} />,
    },
    {
      Header: "Invoice Details",
      id: "txn_invoice_api_details",
      Cell: ({ toggleAllRowsExpanded, row }) => {
        const isRowSelected =
          JSON.stringify(selectedRowDetailArr) ===
          JSON.stringify(row.original.txn_invoice_api_details);
        return (
          <button
            className="underline"
            onClick={async () => {
              toggleAllRowsExpanded(false);
              row.toggleRowExpanded();              
              setSelectedRowDetailArr(row.original.txn_invoice_api_details);
              toggleRowSelection(row.original.txn_invoice_api_details);
            }}
          >
            {isRowSelected ? "Collapse" : "View More"}
          </button>
        );
      },
    },
    {
      Header: "",
      id: "payment",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className={`w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-10 py-1 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm ${
              row.original.invoice_status === INVOICE_STATUS.PAID
                ? "bg-gray-300 text-gray-500 cursor-not-allowed outline outline-gray-500"
                : "hover:bg-blue-500 text-blue-500 hover:text-white hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500"
            }`}
            disabled={row.original.invoice_status === INVOICE_STATUS.PAID}
            onClick={() => handlePayClick(row.original, row.index + 1)}
          >
            Pay
          </button>
        );
      },
    },
  ]);

  const toggleRowSelection = (row) => {
    if (selectedRowDetailArr === row) {
      setSelectedRowDetailArr(null);
    } else {
      setSelectedRowDetailArr(row);
    }
  };

  const invoiceDetails = [
    {
      Header: "Developer Project Name",
      accessor: "developer_proj_name",
    },
    {
      Header: "Cumulative Usage Count",
      accessor: "developer_proj_cumulative_count",
    },
    {
      Header: "Subtotal Amount",
      accessor: "invoice_sub_amt",
    },
    {
      Header: "Total Nett Amount",
      accessor: "invoice_nett_amt",
    },
    {
      Header: "Details",
      id: "project_details",
      Cell: ({ toggleAllRowsExpanded, row }) => {
        const isSubRowSelected =
          JSON.stringify(selectedRowProjectArr) ===
          JSON.stringify(row.original.project_details);
        return (
          <button
            className="underline"
            onClick={() => {
              toggleAllRowsExpanded(false);
              row.toggleRowExpanded();
              setSelectedRowProjectArr(row.original.project_details);
              toggleRowProjectArr(row.original.project_details);
              // console.log(row.original.project_details);
            }}
          >
            {isSubRowSelected ? "Collapse" : "View More"}
          </button>
        );
      },
    },
  ];

  const toggleRowProjectArr = (row) => {
    if (selectedRowProjectArr === row) {
      setSelectedRowProjectArr(null);
    } else {
      setSelectedRowProjectArr(row);
    }
  };

  const projectDetails = [
    {
      Header: "Project API",
      accessor: "project_name",
    },
    {
      Header: "Usage Count",
      accessor: "developer_proj_count",
    },
    {
      Header: "Variable Rate",
      accessor: "api_plan_variable_rate",
    },
  ];

  const handlePayClick = (data, index) => {
    const startDate = new Date(data.invoice_start_datetime)
      .toISOString()
      .split("T")[0];
    const endDate = new Date(data.invoice_end_datetime)
      .toISOString()
      .split("T")[0];
    const billingPeriod = `${startDate} to ${endDate}`;

    const billingData = {
      index: index,
      txn_invoice_id: data.txn_invoice_id,
      billing_summary: {
        invoice_due_datetime: data.invoice_due_datetime,
        billing_period: billingPeriod,
        invoice_sub_total_amt: data.invoice_sub_total_amt,
        discount_percent: data.discount_percent,
        discount_amt: data.discount_amt,
        invoice_total_nett_amt: data.invoice_total_nett_amt,
      },
      txn_invoice_api_details: data.txn_invoice_api_details
    };
    navigate('/subscriptions/invoices/details', { state: { data: billingData } });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFF",
          padding: "20px",
          borderRadius: "10px",
          margin: "20px 0 20px 0 ",
          textAlign: "left",
          color: "black",
        }}
      >
        <row style={{ display: "flex" }}>
          <div style={{ width: "100%", padding: "10px" }}>
            <div
              className="font-bold"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <h6 style={{ marginBottom: "0px" }}>
                {" "}
                Billing Period Start Date{" "}
              </h6>
            </div>
            <input
              type="date"
              style={{ width: "100%", padding: "10px", borderRadius: "8px" }}
              value={startdate}
              onChange={(e) => {
                setStartDate(e.target.value);
                sessionStorage.setItem("startdate", e.target.value);
              }}
            />
          </div>
          <div style={{ width: "100%", padding: "10px" }}>
            <div
              className="font-bold"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <h6 style={{ marginBottom: "0px" }}> Billing Period End Date </h6>
            </div>
            <input
              type="date"
              style={{ width: "100%", padding: "10px", borderRadius: "8px" }}
              value={enddate}
              onChange={(e) => {
                setEndDate(e.target.value);
                sessionStorage.setItem("enddate", e.target.value);
              }}
            />
          </div>
        </row>
        <div style={{ textAlign: "end", paddingTop: "34px" }}>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-12 py-2 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm ${
              isApplying
                ? "bg-blue-500 text-white cursor-not-allowed"
                : "hover:bg-blue-500 text-blue-500 hover:text-white outline outline-blue-500"
            }`}
            onClick={() => applyFilter()}
            disabled={isApplying} // Disable the button when isApplying is true
          >
            {isApplying ? "Applying..." : "Apply"}
          </button>
          <button
            data-inline="true"
            type="button"
            className={`w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-12 py-2 text-base font-bold sm:ml-2 sm:w-auto sm:text-sm ${
              isReseting
                ? "bg-red-500 text-white cursor-not-allowed"
                : "hover:bg-red-500 text-red-500 hover:text-white outline outline-red-500"
            }`}
            onClick={() => resetFilter()}
            disabled={isReseting} // Disable the button when isReseting is true
          >
            {isReseting ? "Reseting..." : "Reset"}
          </button>
        </div>
      </div>

      {/* table */}
      {invoicesData != null ? (
        <div>
          <Table
            columns={columns}
            data={invoicesData}
            filters={columns.map((column) => column.accessor)}
            expandedTable={
              selectedRowDetailArr ? (
                <Table
                  columns={invoiceDetails}
                  data={selectedRowDetailArr}
                  filters={invoiceDetails.map((column) => column.accessor)}
                  expandedTable={
                    selectedRowProjectArr ? (
                      <Table
                        columns={projectDetails}
                        data={selectedRowProjectArr}
                        filters={projectDetails.map(
                          (column) => column.accessor
                        )}
                        hasSearch={false}
                        hasPagination={true}
                      />
                    ) : null
                  }
                  hasSearch={false}
                  hasPagination={true}
                />
              ) : null
            }
          />
        </div>
      ) : (
        <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
          <div className="flex place-content-center w-full">
            <Spinner className="w-20 h-20" />
          </div>
        </div>
      )}
    </>
  );
}
