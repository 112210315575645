import React from "react";

import TxnNetsSuccessStatusLayout from "./txnNetsSuccessStatusLayout";

const TxnNetsSuccessStatusPage = () => {
  return (
    <>
      <TxnNetsSuccessStatusLayout />
    </>
  );
};

export default TxnNetsSuccessStatusPage;